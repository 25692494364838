<template>
  <div v-if="isWriteOff" class="successbox">
    <img class="icon" src="@/assets/icon-success.png">
    <div class="text">本次核销成功</div>
  </div>
  <div v-else>
    <van-card
      :num="order.productQuantity"
      :price="order.productPrice"
      :desc="order.productSkuText"
      :title="order.productName"
      :thumb="order.productImage"
    >
    </van-card>
    <template v-if="order.bizType === 'BOOKING'">
      <van-cell-group title="预约信息">
        <van-cell title="预约日期" :value="order.bookingDate" v-if="order.bookingDate" />
        <van-cell title="剩余凭证" :value="order.credentialTotalQuantity - order.credentialUsedQuantity" />
        <van-cell title="姓名" :value="order.buyerName" />
        <van-cell title="电话" :value="order.buyerPhone" />
      </van-cell-group>
    </template>
    <van-cell-group title="买家留言" v-if="order && order.buyerMessage !== ''">
      <van-cell :title="order.buyerMessage" />
    </van-cell-group>
    <van-cell-group title="核销信息" v-if="order.status === 'WAIT_USE'">
      <van-field v-model="phone" type="tel" name="address" label="商家电话" required input-align="right" placeholder="请输入" />
      <van-field required label="核销备注" v-model="remark" input-align="right" rows="1" autosize type="textarea" placeholder="请输入" />
      <div style="margin-top: 12px;font-size: 12px;color: #999999;padding:0 var(--van-cell-horizontal-padding) 12px;">
        <text>此内容用户可见，请谨慎填写</text>
        <van-button style="margin-top: 8px;" block type="primary" @click="writeoffOrder">确认核销</van-button>
      </div>
    </van-cell-group>
    <van-cell-group title="核销记录" v-if="order && order.writeOffs.length > 0">
      <div class="listbox" v-for="(item,index) in order.writeOffs" :key="index">
        <div class="headbox">
          <div class="userphone">商家电话：{{item.phone}}</div>
          <div class="time">{{item.createTime}}</div>
        </div>
        <div class="remark">{{item.remark}}</div>
      </div>
    </van-cell-group>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const state = reactive({
      orderId: useRoute.query.orderId,
      order: '',
      phone: '',
      remark: '',
      storeId: '',
      isWriteOff: false
    })
    const getOrder = () => {
      post('/order.get', {
        orderId: state.orderId
      }).then(res => {
        if (res.code === 0) {
          state.order = res.data
          state.storeId = res.data.store.storeId
        } else {
          Toast(res.msg)
        }
      })
    }
    const writeoffOrder = () => {
      if (!state.phone) return Toast('请输入用户电话')
      if (!state.remark) return Toast('请输入核销备注')
      Toast.loading({ forbidClick: true })
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        let payHttpUrl = '/order.verifyCredential'
        if (res.data.storeMerchant) {
          if (res.data.storeMerchant.applymentState === 'FINISH') {
            payHttpUrl = '/wechatVerifyCredential'
          }
        }
        post(payHttpUrl, {
          orderId: state.orderId,
          phone: state.phone,
          remark: state.remark,
          quantity: 1
        }).then(res => {
          Toast.clear()
          if (res.code === 0) {
            state.isWriteOff = true
            getOrder()
            state.phone = ''
            state.remark = ''
            Toast.success('核销成功')
          } else {
            Toast(res.msg)
          }
        })
      })
    }
    const init = () => {
      getOrder()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getOrder,
      writeoffOrder
    }
  }
}
</script>

<style scoped>
  .listbox{
    background: #FFFFFF;
    position: relative;
    padding:var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
  }
  .listbox::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: var(--van-padding-md);
    bottom: 0;
    left: var(--van-padding-md);
    border-bottom: 1px solid var(--van-cell-border-color);
    transform: scaleY(.5);
  }
  .listbox .headbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
  .listbox .headbox .time{
    color: #999999;
  }
  .listbox .remark{
    margin-top: 8px;
    text-align: justify;
    font-size: 12px;
    color: #999999;
  }
  .successbox{
    background: #FFFFFF;
    height: 100vh;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20vh;
    box-sizing: border-box;
  }
  .successbox .icon{
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .successbox .text{
    margin-top: 12px;
    font-size: 16px;
    color: #666666;
  }
</style>
